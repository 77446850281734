import { useStaticQuery, graphql } from "gatsby"
export const  useSpaData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesSpaYaml {
          heading {
            headingText
            subHeadingText
          },
          sectionOne {
            image {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageAlt
            headingText
            contentText
            linkUrl
            linkText
          }
        }
      }
    `
  )
  return data.pagesSpaYaml
}