import React from "react";
import EnhancedMarkdown from '../../helpers/enhanced-markdown'
import Img from "gatsby-image"

class Module extends React.Component {
    render() {
        const { imagePlacement, isGatsbyImage, image, imageAlt, headingText, contentText, linkUrl, linkText, classes } = this.props;

        const TextContainer = () => {
          return (
            <div className="section-text-container">
              <h1 className="text-align-center text-align-small-left">
                {headingText}
              </h1>
              <div className="p">
                  <EnhancedMarkdown>{ contentText }</EnhancedMarkdown>
              </div>
              <footer>
                <a href={linkUrl} className="button">{linkText}</a>
              </footer>
            </div>
          )
        };

        const ImageContainer = () => {
          return (
            <div className="section-image-container">
              {isGatsbyImage ?
                <Img
                  fluid={ image }
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className="section-image"
                  alt={ imageAlt }
                /> :
                <img src={image} alt={imageAlt} className="section-image" />
              }
            </div>
          )
        };

        return (
          <section className={ ('image-placement-' + imagePlacement) + ' ' + ( classes || '' )}>
            <div className="container">
              <div className="row">
                <div className="col-100 col-small-1-2 vertical-align-middle">
                  { imagePlacement === 'left' ? <ImageContainer /> : <TextContainer /> }
                </div>
                <div className="col-100 col-small-1-2 vertical-align-middle">
                { imagePlacement == 'left' ? <TextContainer /> : <ImageContainer /> }
                </div>
              </div>
            </div>
          </section>
        )
    }
}

export default Module