import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadingModule from "../components/modules/heading-module"
import TwoColumnTextImageModule from "../components/modules/two-column-text-image"
import MarkdownModule from "../components/modules/html-markdown"

import { useSpaData } from "../hooks/use-spa-data"
class SpaPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

	const ContentContainer = () => {
		const { heading, sectionOne, sectionTwo } = useSpaData();
		return (
			<div>
				<HeadingModule
					headingText={ heading.headingText }
					subHeadingText={ heading.subHeadingText }
				/>
				<TwoColumnTextImageModule
					imagePlacement="left"
					isGatsbyImage={ true }
					image={ sectionOne.image.childImageSharp.fluid }
					imageAlt={ sectionOne.imageAlt }
					headingText={ sectionOne.headingText }
					contentText={ sectionOne.contentText }
					linkUrl={ sectionOne.linkUrl }
					linkText={ sectionOne.linkText }
					classes="darker spa-section py-5 extra-padding"
				/>
			</div>
		)
	}

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Spa - Niwot Inn &amp; Spa" />
		<div className="spa-page">
			<ContentContainer />
		</div>
      </Layout>
    )
  }
}

export default SpaPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
